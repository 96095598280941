export default [
  {
    path: '/booking-driving-range',
    name: 'booking-driving-range',
    component: () => import('@/views/modules/booking-driving-range/BookingDrivingRange.vue'),
    meta: {
      pageTitle: 'Booking Driving Range',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Booking Driving Ranget',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tee-sheet',
    name: 'tee-sheet',
    component: () => import('@/views/modules/tee-sheet/TeeSheet.vue'),
    meta: {
      pageTitle: 'Tee Sheet',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Tee Sheet',
          active: true,
        },
      ],
    },
  },
  {
    path: '/caddy-setting',
    name: 'caddy-setting',
    component: () => import('@/views/modules/caddy-setting/CaddySetting.vue'),
    meta: {
      pageTitle: 'Caddy Setting',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Caddy Setting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/member-card',
    name: 'member-card',
    component: () => import('@/views/modules/member-card/MemberCard.vue'),
    meta: {
      pageTitle: 'Member Card',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Member Card Setting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/members',
    name: 'members',
    component: () => import('@/views/modules/members/Member.vue'),
    meta: {
      pageTitle: 'Member',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Member Setting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/visitor',
    name: 'visitor',
    component: () => import('@/views/modules/visitor/Visitor.vue'),
    meta: {
      pageTitle: 'Visitor',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Visitor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/caddy-calendar',
    name: 'caddy-calendar',
    component: () => import('@/views/modules/caddy-calendar/CaddyCalendar.vue'),
    meta: {
      pageTitle: 'Caddy Calendar',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Caddy Calendar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/caddyEvaluation',
    name: 'caddy-evaluation',
    component: () => import('@/views/modules/caddy-evaluation/CaddyEvaluation.vue'),
    meta: {
      pageTitle: 'CaddyEvaluation',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Starter',
          active: true,
        },
      ],
    },
  },
  {
    path: '/starter',
    name: 'starter',
    component: () => import('@/views/modules/starter/starter.vue'),
    meta: {
      pageTitle: 'Starter',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Starter',
          active: true,
        },
      ],
    },
  },
  {
    path: '/starter-form-list',
    name: 'starter-form-list',
    component: () => import('@/views/modules/starter-form-list/StarterFormList.vue'),
    meta: {
      pageTitle: 'StarterFormList',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'StarterFormList',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking',
    name: 'booking',
    component: () => import('@/views/modules/booking/Booking.vue'),
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Booking',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking-print',
    name: 'bookingPrint',
    component: () => import('@/views/modules/booking/BookingPrint.vue'),
    meta: {
      pageTitle: 'Booking Print',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Booking Print',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking-driving-range-print',
    name: 'bookingDrivingRangePrint',
    component: () => import('@/views/modules/booking-driving-range/BookingDrivingRangePrint.vue'),
    meta: {
      pageTitle: 'Booking Print',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Booking Print',
          active: true,
        },
      ],
    },
  },
  {
    path: '/confirm-email/:id/:type',
    name: 'confirmEmail',
    component: () => import('@/views/modules/booking/Email/confirmEmail.vue'),
    meta: {
      pageTitle: 'Confirm Email',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Confirm Email',
          active: true,
        },
      ],
    },
  },
  {
    path: '/confirm-emailG/:BookingCode',
    name: 'confirmEmailGroup',
    component: () => import('@/views/modules/booking/Email/confirmEmailGroup.vue'),
    meta: {
      pageTitle: 'Confirm Email',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Confirm Email',
          active: true,
        },
      ],
    },
  },
  {
    path: '/confirm-emailGr/:BookingCode',
    name: 'confirmEmailGroupAll',
    component: () => import('@/views/modules/booking/Email/EmailConfirmation.vue'),
    meta: {
      pageTitle: 'Confirm Email',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Confirm Email',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking-list',
    name: 'bookinglist',
    component: () => import('@/views/modules/booking-list/RF01.vue'),
    meta: {
      pageTitle: 'Booking List',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Booking List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking-dr-invoice/:id',
    name: 'bookingdrinvoice',
    component: () => import('@/views/modules/booking-driving-range/invoiceDR/invoiceIV01Dr.vue'),
    meta: {
      pageTitle: 'Booking Driving Range Invoice',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Booking Driving Range Invoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report-list',
    name: 'report',
    component: () => import('@/views/modules/report/ReportList.vue'),
    meta: {
      pageTitle: 'Report',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/retail-invoice/:id',
    name: 'retailInvoice',
    component: () => import('@/views/modules/ultils/PaymentForMember/InvoiceForMember.vue'),
    meta: {
      pageTitle: 'RetailInvoice',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'RetailInvoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/modules/welcome/welcome.vue'),
    meta: {
      pageTitle: 'welcome',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'welcome',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/modules/user/user.vue'),
    meta: {
      pageTitle: 'user',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'user',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoice-VAT/:id',
    name: 'InvoiceVAT',
    component: () => import('@/views/modules/invoice/ListPayment/InvoiceVAT.vue'),
    meta: {
      pageTitle: 'InvoiceVAT',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'InvoiceVAT',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-processing-history',
    name: 'DataProcessingHistory',
    component: () => import('@/views/modules/data-processing/dataProcessingHistory.vue'),
    meta: {
      pageTitle: 'DataProcessingHistory',
      breadcrumb: [
        {
          text: 'DataProcessingHistory',
        },
        {
          text: 'DataProcessingHistory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-processing',
    name: 'DataProcessing',
    component: () => import('@/views/modules/data-processing/dataProcessing.vue'),
    meta: {
      pageTitle: 'DataProcessing',
      breadcrumb: [
        {
          text: 'DataProcessing',
        },
        {
          text: 'DataProcessing',
          active: true,
        },
      ],
    },
  },
  {
    path: '/print-teesheet/:time',
    name: 'PrintTeeSheet',
    component: () => import('@/views/modules/booking/PrintTeeSheet.vue'),
    meta: {
      pageTitle: 'Print TeeSheet',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Print TeeSheet',
          active: true,
        },
      ],
    },
  },
  {
    path: '/wating-list',
    name: 'WatingList',
    component: () => import('@/views/modules/wating-list/WatingList.vue'),
    meta: {
      pageTitle: 'Wating List',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Wating List',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/add-service-revenue-center',
  //   name: 'ListServiceRevenueCenter',
  //   component: () => import('@/views/modules/RevenueCenter/AddServiceNoRevenueCenter/AddServiceNoRevenueCenter.vue'),
  //   meta: {
  //     pageTitle: 'Add Service RevueneCenter',
  //     breadcrumb: [
  //       {
  //         text: 'Components',
  //       },
  //       {
  //         text: 'Add Service RevueneCenter',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/revert',
    name: 'revert',
    component: () => import('@/views/modules/revert-BDC/revert-BDC.vue'),
    meta: {
      pageTitle: 'revert',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'revert',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gzip',
    name: 'gzip',
    component: () => import('@/views/modules/gzip/gzipData.vue'),
    meta: {
      pageTitle: 'gzip',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'gzip',
          active: true,
        },
      ],
    },
  },
  {
    path: '/locker-setting',
    name: 'locker-setting',
    component: () => import('@/views/modules/locker-setting/LockerSetting.vue'),
    meta: {
      pageTitle: 'Locker',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'Locker',
          active: true,
        },
      ],
    },
  },
  {
    path: '/locker-inventory',
    name: 'locker-inventory',
    component: () => import('@/views/modules/locker-inventory/LockerInventory.vue'),
    meta: {
      pageTitle: 'LockerInventory',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'LockerInventory',
          active: true,
        },
      ],
    },
  },
  {
    path: '/system-parameters',
    name: 'SystemParameters',
    component: () => import('@/views/modules/config-system-parameters/config-system-parameters.vue'),
    meta: {
      pageTitle: 'System Parameters',
      breadcrumb: [
        {
          text: 'Components',
        },
        {
          text: 'System Parameters',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tournament',
    name: 'Tournament',
    component: () => import('@/views/modules/Tournament/Tournament.vue'),
    meta: {
      pageTitle: 'Tournament',
      breadcrumb: [
        {
          text: 'Tournament',
        },
        {
          text: 'Tournament',
          active: true,
        },
      ],
    },
  }
]
