<template>
  <div class="vue-daterange-picker" :class="{ inline: opens === 'inline' }">
    <div
      id="2323"
      ref="toggle"
      class="cursor-pointer"
      :class="controlContainerClass"
      @click="onClickPicker"
    >
      <slot
        name="input"
        :startDate="start"
        :endDate="end"
        :ranges="ranges"
        :rangeText="rangeText"
      >
        <div v-if="showDaysOfWeek">
          <span v-if="DayOfWeek == 1">{{ $t('DAYS_OF_WEEK_MON') }}, </span>
          <span v-if="DayOfWeek == 2">{{ $t('DAYS_OF_WEEK_TUE') }}, </span>
          <span v-if="DayOfWeek == 3">{{ $t('DAYS_OF_WEEK_WED') }}, </span>
          <span v-if="DayOfWeek == 4">{{ $t('DAYS_OF_WEEK_THU') }}, </span>
          <span v-if="DayOfWeek == 5">{{ $t('DAYS_OF_WEEK_FRI') }}, </span>
          <span v-if="DayOfWeek == 6">{{ $t('DAYS_OF_WEEK_SAT') }}, </span>
          <span v-if="DayOfWeek == 0">{{ $t('DAYS_OF_WEEK_SUN') }}, </span>
        </div>
        <div v-if="dateRange" class="input-mask" style="flex: 1">
          <template v-if="!singleDatePicker">
            <span>
              <feather-icon icon="ArrowRightIcon" size="14" />
            </span>
            {{ mask.end }}
          </template>
          <template v-else>
            <!-- {{ mask.start }} -->
            <input
              v-model="mask.start"
              v-input-mask
              class="date-mask"
              data-inputmask-alias="datetime"
              :data-inputmask-inputformat="
                timePicker ? 'dd/mm/yyyy HH:MM' : 'dd/mm/yyyy'
              "
              :placeholder="timePicker ? 'dd/mm/yyyy hh:mm' : 'dd/mm/yyyy'"
              @input="updateDateRange()"
            />
          </template>
        </div>
        <div v-else>DD/MM/YYYY</div>
        <ez-icon
          icon="ezGolf-icon-calendar"
          size="18"
          class="cursor-pointer"
          @click="onClickPicker"
        />
      </slot>
    </div>
    <transition name="slide-fade" mode="out-in">
      <div
        v-if="open || opens === 'inline'"
        ref="dropdown"
        v-append-to-body
        class="daterangepicker ltr"
        :class="pickerStyles"
      >
        <!--
          Optional header slot (same props as footer) @see footer slot for documentation
        -->
        <slot
          name="header"
          :rangeText="rangeText"
          :locale="locale"
          :clickCancel="clickCancel"
          :clickApply="clickedApply"
          :in_selection="in_selection"
          :autoApply="autoApply"
        />

        <div class="calendars">
          <!--
            Allows you to change the range

            @param {Date} startDate - current startDate
            @param {Date} endDate - current endDate
            @param {object} ranges - object with ranges
            @param {Fn} clickRange(dateRange) - call to select the dateRange - any two date objects or an object from tha ranges array
          -->
          <slot
            v-if="showRanges"
            name="ranges"
            :startDate="start"
            :endDate="end"
            :ranges="ranges"
            :clickRange="clickRange"
          >
            <calendar-ranges
              :always-show-calendars="alwaysShowCalendars"
              :locale-data="locale"
              :ranges="ranges"
              :selected="{ startDate: start, endDate: end }"
              @clickRange="clickRange"
              @showCustomRange="showCustomRangeCalendars = true"
            />
          </slot>

          <div v-if="showCalendars" class="calendars-container">
            <div
              class="drp-calendar col left"
              :class="{ single: singleDatePicker }"
            >
              <div v-if="false" class="daterangepicker_input d-none d-sm-block">
                <input
                  class="input-mini form-control"
                  type="text"
                  name="daterangepicker_start"
                  :value="startText"
                />
                <i class="fa fa-calendar glyphicon glyphicon-calendar" />
              </div>
              <div class="calendar-table">
                <calendar
                  :key="open"
                  :month-date="monthDate"
                  :locale-data="locale"
                  :start="start"
                  :end="end"
                  :min-date="min"
                  :max-date="max"
                  :show-dropdowns="showDropdowns"
                  :date-format="dateFormatFn"
                  :show-week-numbers="showWeekNumbers"
                  :year-start="yearStart"
                  :year-end="yearEnd"
                  :time-zone="timeZone"
                  @change-month="changeLeftMonth"
                  @date-click="dateClick"
                  @hover-date="hoverDate"
                >
                  <slot
                    slot="date-slot"
                    slot-scope="data"
                    name="date"
                    v-bind="data"
                  />
                </calendar>
              </div>
              <calendar-time
                v-if="timePicker && start"
                :miniute-increment="timePickerIncrement"
                :hour24="timePicker24Hour"
                :second-picker="timePickerSeconds"
                :current-time="start"
                :readonly="readonly"
                :time-zone="timeZone"
                @update="onUpdateStartTime"
              />
            </div>

            <div v-if="!singleDatePicker" class="drp-calendar col right">
              <div v-if="false" class="daterangepicker_input">
                <input
                  class="input-mini form-control"
                  type="text"
                  name="daterangepicker_end"
                  :value="endText"
                />
                <i class="fa fa-calendar glyphicon glyphicon-calendar" />
              </div>
              <div class="calendar-table">
                <calendar
                  :month-date="nextMonthDate"
                  :locale-data="locale"
                  :start="start"
                  :end="end"
                  :min-date="min"
                  :max-date="max"
                  :show-dropdowns="showDropdowns"
                  :date-format="dateFormatFn"
                  :show-week-numbers="showWeekNumbers"
                  :time-zone="timeZone"
                  :year-start="yearStart"
                  :year-end="yearEnd"
                  @change-month="changeRightMonth"
                  @date-click="dateClick"
                  @hover-date="hoverDate"
                >
                  <!--
                    Allows you to change date cell slot. By default it renders the day number

                    @param {Date} date - the date being rendered into the table cell
                  -->
                  <slot
                    slot="date-slot"
                    slot-scope="data"
                    name="date"
                    v-bind="data"
                  />
                </calendar>
              </div>
              <calendar-time
                v-if="timePicker && end"
                :miniute-increment="timePickerIncrement"
                :hour24="timePicker24Hour"
                :second-picker="timePickerSeconds"
                :current-time="end"
                :readonly="readonly"
                :time-zone="timeZone"
                @update="onUpdateEndTime"
              />
            </div>
          </div>
        </div>
        <!--
          Allows you to change footer of the component (where the buttons are)

          @param {string} rangeText - the formatted date range by the component
          @param {object} locale - the locale object @see locale prop
          @param {function} clickCancel - function which is called when you want to cancel the range picking and reset old values
          @param {function} clickApply -function which to call when you want to apply the selection
          @param {boolean} in_selection - is the picker in selection mode
          @param {boolean} autoApply - value of the autoApply prop (whether to select immediately)
        -->
        <slot
          name="footer"
          :rangeText="rangeText"
          :locale="locale"
          :clickCancel="clickCancel"
          :clickApply="clickedApply"
          :in_selection="in_selection"
          :autoApply="autoApply"
        >
          <div v-if="!autoApply" class="drp-buttons">
            <button
              v-if="!readonly"
              class="cancelBtn btn btn-sm btn-secondary"
              type="button"
              @click="clickCancel"
            >
              {{ locale.cancelLabel }}
            </button>
            <button
              v-if="!readonly"
              class="applyBtn btn btn-sm btn-success"
              :disabled="in_selection"
              type="button"
              @click="clickedApply"
            >
              {{ locale.applyLabel }}
            </button>
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import dateUtilMixin from './dateUtilMixin';
import Calendar from './Calendar.vue';
import CalendarTime from './CalendarTime';
import CalendarRanges from './CalendarRanges';
import { getDateUtil } from './util';
import appendToBody from '../directives/appendToBody';

export default {
  components: {
    Calendar,
    CalendarTime,
    CalendarRanges,
  },
  directives: { appendToBody },
  mixins: [dateUtilMixin],
  inheritAttrs: false,
  model: {
    prop: 'dateRange',
    event: 'update',
  },
  props: {
    /**
     * minimum date allowed to be selected
     * @default null
     */
    minDate: {
      type: [String, Date],
      default() {
        return null;
      },
    },
    /**
     * maximum date allowed to be selected
     * @default null
     */
    maxDate: {
      type: [String, Date],
      default() {
        return null;
      },
    },
    /**
     * Show the week numbers on the left side of the calendar
     */
    showWeekNumbers: {
      type: Boolean,
      default: false,
    },
    showDaysOfWeek: {
      type: Boolean,
      default: false,
    },
    /**
     * Each calendar has separate navigation when this is false
     */
    linkedCalendars: {
      type: Boolean,
      default: true,
    },
    /**
     * Only show a single calendar, with or without ranges.
     *
     * Set true or 'single' for a single calendar with no ranges, single dates only.
     * Set 'range' for a single calendar WITH ranges.
     * Set false for a double calendar with ranges.
     */
    singleDatePicker: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Show the dropdowns for month and year selection above the calendars
     */
    showDropdowns: {
      type: Boolean,
      default: false,
    },
    /**
     * Show the dropdowns for time (hour/minute) selection below the calendars
     */
    timePicker: {
      type: Boolean,
      default: false,
    },
    /**
     * Determines the increment of minutes in the minute dropdown
     */
    timePickerIncrement: {
      type: Number,
      default: 5,
    },
    /**
     * Use 24h format for the time
     */
    timePicker24Hour: {
      type: Boolean,
      default: true,
    },
    /**
     * Allows you to select seconds except hour/minute
     */
    timePickerSeconds: {
      type: Boolean,
      default: false,
    },
    /**
     * Auto apply selected range. If false you need to click an apply button
     */
    autoApply: {
      type: Boolean,
      default: false,
    },
    /**
     * Object containing locale data used by the picker. See example below the table
     *
     * @default *see below
     */
    localeData: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * This is the v-model prop which the component uses. This should be an object containing startDate and endDate props.
     * Each of the props should be a string which can be parsed by Date, or preferably a Date Object.
     * @default {
     * startDate: null,
     * endDate: null
     * }
     */
    dateRange: {
      // for v-model
      type: [Object, String, Date],
      default: null,
      required: false,
    },
    /**
     * You can set this to false in order to hide the ranges selection. Otherwise it is an object with key/value. See below
     * @default *see below
     */
    ranges: {
      type: [Object, Boolean],
      default() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const todayEnd = new Date();
        todayEnd.setHours(11, 59, 59, 999);

        const yesterdayStart = new Date();
        yesterdayStart.setDate(today.getDate() - 1);
        yesterdayStart.setHours(0, 0, 0, 0);

        const yesterdayEnd = new Date();
        yesterdayEnd.setDate(today.getDate() - 1);
        yesterdayEnd.setHours(11, 59, 59, 999);

        const thisMonthStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        const thisMonthEnd = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0,
          11,
          59,
          59,
          999
        );

        return {
          Today: [today, todayEnd],
          Yesterday: [yesterdayStart, yesterdayEnd],
          'This month': [thisMonthStart, thisMonthEnd],
          'This year': [
            new Date(today.getFullYear(), 0, 1),
            new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999),
          ],
          'Last month': [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999),
          ],
        };
      },
    },
    /**
     * which way the picker opens - "center", "left", "right" or "inline"
     */
    opens: {
      type: String,
      default: 'center',
    },
    /**
     function(classes, date) - special prop type function which accepts 2 params:
     "classes" - the classes that the component's logic has defined,
     "date" - tha date currently processed.
     You should return Vue class object which should be applied to the date rendered.
     */
    dateFormat: Function,
    /**
     * If set to false and one of the predefined ranges is selected then calendars are hidden.
     * If no range is selected or you have clicked the "Custom ranges" then the calendars are shown.
     */
    alwaysShowCalendars: {
      type: Boolean,
      default: true,
    },
    /**
     * Disabled state. If true picker do not popup on click.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Class of html picker control container
     */
    controlContainerClass: {
      type: [Object, String],
      default: 'form-control reportrange-text', /// form-control
    },
    /**
     * Append the dropdown element to the end of the body
     * and size/position it dynamically. Use it if you have
     * overflow or z-index issues.
     * @type {Boolean}
     */
    appendToBody: {
      type: Boolean,
      default: false,
    },
    /**
     * When `appendToBody` is true, this function is responsible for
     * positioning the drop down list.
     *
     * If a function is returned from `calculatePosition`, it will
     * be called when the drop down list is removed from the DOM.
     * This allows for any garbage collection you may need to do.
     *
     * @since v0.5.1
     */
    calculatePosition: {
      type: Function,
      /**
       * @param dropdownList {HTMLUListElement}
       * @param component {Vue} current instance of vue date range picker
       * @param width {int} calculated width in pixels of the dropdown menu
       * @param top {int} absolute position top value in pixels relative to the document
       * @param left {int} absolute position left value in pixels relative to the document
       * @param right {int} absolute position right value in pixels relative to the document
       * @return {function|void}
       */
      default(dropdownList, component, { width, top, left, right }) {
        // which way the picker opens - "center", "left" or "right"
        if (component.opens === 'center') {
          // // console.log('center open', left, width)
          dropdownList.style.left = `${left + width / 2}px`;
        } else if (component.opens === 'left') {
          // // console.log('left open', right, width)
          dropdownList.style.right = `${window.innerWidth - right}px`;
        } else if (component.opens === 'right') {
          // // console.log('right open')
          dropdownList.style.left = `${left}px`;
        }
        dropdownList.style.top = `${top}px`;
        // dropdownList.style.width = width + 'px'
      },
    },
    /**
     * Whether to close the dropdown on "esc"
     */
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
    /**
     * Makes the picker readonly. No button in footer. No ranges. Cannot change.
     */
    readonly: {
      type: Boolean,
    },
    /**
     * Long HQ - 14/10/2021 Thêm prop yearBefore, yearAfter để hiển thị năm lân cận của năm hiện tại, dùng cho select year
     */
    yearStart: {
      type: Number,
      default: null,
    },
    yearEnd: {
      type: Number,
      default: null,
    },
  },
  data() {
    // copy locale data object
    const util = getDateUtil();
    const data = { locale: util.localeData({ ...this.localeData }) };
    data.timeZone = localStorage.getItem('timeZone');
    if (this.dateRange) {
      let startDate = this.dateRange.startDate || this.dateRange || null;
      let endDate = this.dateRange.endDate || this.dateRange || null;

      // Vì DB trả ra ko có Z, và không trả mili giây đối với giờ tròn, replace ký tự cuối bằng ký tự Z
      // Không tìm thấy .000 ( giờ tròn ) do api trả ko có Z
      if (startDate.search(/[^.]*$/) == 0) {
        // Không có chữ Z thì mới add Z
        if (startDate.search(/[^Z]*$/) == 0) {
          startDate += 'Z';
        }
      } else {
        // Nếu không tìm thấy Z mới add thêm Z
        // eslint-disable-next-line no-lonely-if
        if (startDate.search(/[^Z]*$/) == 0) {
          startDate = startDate.replace(/[^.]*$/, 'ABC').replace('.ABC', 'Z');
        }
      }

      if (endDate.search(/[^.]*$/) == 0) {
        // Không có chữ Z thì mới add Z
        if (endDate.search(/[^Z]*$/) == 0) {
          endDate += 'Z';
        }
      } else {
        // Nếu không tìm thấy Z mới add thêm Z
        // eslint-disable-next-line no-lonely-if
        if (endDate.search(/[^Z]*$/) == 0) {
          endDate = endDate.replace(/[^.]*$/, 'ABC').replace('.ABC', 'Z');
        }
      }

      // Chuyển date time sang timezone cấu hình
      const newStartDate = this.dayjs(startDate)
        .utc()
        .tz(data.timeZone)
        .format();
      const newEndDate = this.dayjs(endDate).utc().tz(data.timeZone).format();

      data.monthDate = startDate
        ? this.dayjs(startDate).utc().tz(data.timeZone).toDate()
        : new Date();
      // get next month date
      data.nextMonthDate = util.nextMonth(data.monthDate);

      data.start = startDate ? newStartDate : null;

      if (this.singleDatePicker && this.singleDatePicker !== 'range') {
        // ignore endDate for singleDatePicker
        data.end = data.start;
      } else {
        data.end = endDate ? newEndDate : null;
      }
    } else {
      data.monthDate = new Date();
      // get next month date
      data.nextMonthDate = util.nextMonth(data.monthDate);

      data.start = null;
      data.end = null;
    }

    data.in_selection = false;
    data.open = false;
    // When alwaysShowCalendars = false and custom range is clicked
    data.showCustomRangeCalendars = false;

    // update day names order to firstDay
    if (data.locale.firstDay !== 0) {
      let iterator = data.locale.firstDay;
      const weekDays = [...data.locale.daysOfWeek];
      while (iterator > 0) {
        weekDays.push(weekDays.shift());
        iterator--;
      }
      data.locale.daysOfWeek = weekDays;
    }

    data.mask = {
      start: this.timePicker
        ? this.dayjs(data.start)
            .utc()
            .tz(data.timeZone)
            .format('DD/MM/YYYY HH:mm')
        : this.dayjs(data.start).utc().tz(data.timeZone).format('DD/MM/YYYY'),
      end: this.timePicker
        ? this.dayjs(data.end)
            .utc()
            .tz(data.timeZone)
            .format('DD/MM/YYYY HH:mm')
        : this.dayjs(data.start).utc().tz(data.timeZone).format('DD/MM/YYYY'),
    };
    data.DayOfWeek = this.dayjs(data.start).utc().tz(data.timeZone).day();
    return data;
  },
  computed: {
    showRanges() {
      return this.ranges !== false && !this.readonly;
    },
    showCalendars() {
      return this.alwaysShowCalendars || this.showCustomRangeCalendars;
    },
    // startText() {
    //   if (this.start === null) return ''
    //   return this.$dateUtil.format(this.start, this.locale.format)
    // },
    // endText() {
    //   if (this.end === null) return ''
    //   return this.$dateUtil.format(this.end, this.locale.format)
    // },
    rangeText() {
      let range = this.startText;
      if (!this.singleDatePicker || this.singleDatePicker === 'range') {
        range += this.locale.separator + this.endText;
      }
      return range;
    },
    min() {
      return this.minDate ? this.minDate : null;
    },
    max() {
      return this.maxDate ? this.maxDate : null;
    },
    pickerStyles() {
      return {
        'show-calendar': this.open || this.opens === 'inline',
        'show-ranges': this.showRanges,
        'show-weeknumbers': this.showWeekNumbers,
        single: this.singleDatePicker,
        [`opens${this.opens}`]: true,
        linked: this.linkedCalendars,
        'hide-calendars': !this.showCalendars,
      };
    },
    isClear() {
      return !this.dateRange.startDate || !this.dateRange.endDate;
    },
    isDirty() {
      const origStart = new Date(this.dateRange.startDate);
      const origEnd = new Date(this.dateRange.endDate);

      return (
        !this.isClear &&
        (this.start.getTime() !== origStart.getTime() ||
          this.end.getTime() !== origEnd.getTime())
      );
    },
  },
  watch: {
    // mask() {
    //   this.selectMonthDate()
    //   // let dt = this.$dateUtil.validateDateRange(this.monthDate, this.minDate || new Date(), this.maxDate)
    //   // this.changeLeftMonth({year: dt.getFullYear(), month: dt.getMonth() + 1})
    // },
    // maxDate() {
    //   this.selectMonthDate()
    //   // let dt = this.$dateUtil.validateDateRange(this.nextMonthDate, this.minDate, this.maxDate || new Date())
    //   // if (this.singleDatePicker !== false)
    //   //   this.changeLeftMonth({year: dt.getFullYear(), month: dt.getMonth() + 1})
    //   // else
    //   //   this.changeRightMonth({year: dt.getFullYear(), month: dt.getMonth() + 1})
    // },
    dateRange(value) {
      const util = getDateUtil();
      if (this.singleDatePicker) {
        this.start = value;
        this.end = value;
        if (this.timePicker) {
          this.mask.start = this.dayjs(value)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY HH:mm');
          this.mask.end = this.dayjs(value)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY HH:mm');
        } else {
          this.mask.start = this.dayjs(value)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY');
          this.mask.end = this.dayjs(value)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY');
        }
      } else {
        this.start = value.startDate;
        this.end = value.endDate;
        if (this.timePicker) {
          this.mask.start = this.dayjs(value)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY HH:mm');
          this.mask.end = this.dayjs(value)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY HH:mm');
        } else {
          this.mask.start = this.dayjs(value)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY');
          this.mask.end = this.dayjs(value)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY');
        }
      }

      this.monthDate = this.start
        ? this.dayjs(this.start).utc().tz(this.timeZone).toDate()
        : new Date();
      // get next month date
      this.nextMonthDate = util.nextMonth(this.monthDate);

      // Không dùng watch để cập nhật lại lịch được vì trùng với sự kiện chọn lịch hoặc update dữ liệu từ api
    },
    'dateRange.startDate': function (value) {
      if (!this.$dateUtil.isValidDate(new Date(value))) {
        return;
      }

      this.start =
        !!value && !this.isClear && this.$dateUtil.isValidDate(new Date(value))
          ? new Date(value)
          : null;
      if (this.isClear) {
        this.start = null;
        this.end = null;
      } else {
        this.start = new Date(this.dateRange.startDate);
        this.end = new Date(this.dateRange.endDate);
      }
    },
    'dateRange.endDate': function (value) {
      if (!this.$dateUtil.isValidDate(new Date(value))) {
        return;
      }

      this.end = !!value && !this.isClear ? new Date(value) : null;
      if (this.isClear) {
        this.start = null;
        this.end = null;
      } else {
        this.start = new Date(this.dateRange.startDate);
        this.end = new Date(this.dateRange.endDate);
      }
    },
    open: {
      handler(value) {
        if (typeof document === 'object') {
          // this.selectMonthDate() // select initial visible months

          this.$nextTick(() => {
            value
              ? document.body.addEventListener('click', this.clickAway)
              : document.body.removeEventListener('click', this.clickAway);
            value
              ? document.addEventListener('keydown', this.handleEscape)
              : document.removeEventListener('keydown', this.handleEscape);

            if (!this.alwaysShowCalendars && this.ranges) {
              this.showCustomRangeCalendars = !Object.keys(this.ranges).find(
                (key) =>
                  this.$dateUtil.isSame(
                    this.start,
                    this.ranges[key][0],
                    'date'
                  ) &&
                  this.$dateUtil.isSame(this.end, this.ranges[key][1], 'date')
              );
            }
          });
        }
      },
      immediate: true,
    },
    start() {
      this.DayOfWeek = this.dayjs(this.start).utc().tz(this.timeZone).day();
    },
  },
  methods: {
    updateDateRange() {
      if (this.mask.start) {
        // Lấy thông tin trên input mask
        const tempStart = this.mask.start;

        let tempEnd = this.mask.end;

        if (this.singleDatePicker) {
          tempEnd = this.mask.start;
        }

        let dateStart = tempStart.split(/\D/).reverse().join('-');
        const dateEnd = tempEnd.split(/\D/).reverse().join('-');

        // Check input mask không hợp lệ thì ko cập nhật lại lịch
        if (this.timePicker) {
          if (
            +tempStart.split(/\D/)[0] > 31 ||
            +tempStart.split(/\D/)[1] > 12 ||
            +tempStart.split(/\D/)[2] < 1900 ||
            +tempStart.split(/\D/)[3].length < 2 ||
            tempStart.split(/\D/)[4].length < 2
          ) {
            return true;
          }

          dateStart = `${tempStart.split(/\D/)[2]}-${
            tempStart.split(/\D/)[1]
          }-${tempStart.split(/\D/)[0]} ${tempStart.split(/\D/)[3]}:${
            tempStart.split(/\D/)[4]
          }`;
          // Cập nhật ngày trên lịch
          this.start = this.dayjs(dateStart)
            .utc()
            .tz(this.timeZone)
            .startOf('day')
            .set('hour', tempStart.split(/\D/)[3])
            .set('minute', tempStart.split(/\D/)[4])
            .format();

          this.end = this.dayjs(dateStart)
            .utc()
            .tz(this.timeZone)
            .startOf('day')
            .set('hour', tempStart.split(/\D/)[3])
            .set('minute', tempStart.split(/\D/)[4])
            .format();
        } else {
          dateStart = `${tempStart.split(/\D/)[2]}-${
            tempStart.split(/\D/)[1]
          }-${tempStart.split(/\D/)[0]}`;
          // Cập nhật ngày trên lịch
          this.start = this.dayjs(dateStart)
            .utc()
            .tz(this.timeZone)
            .startOf('day')
            .format();

          this.end = this.dayjs(dateEnd)
            .utc()
            .tz(this.timeZone)
            .startOf('day')
            .format();

          if (
            +tempStart.split(/\D/)[0] > 31 ||
            +tempStart.split(/\D/)[1] > 12 ||
            +tempStart.split(/\D/)[2] < 1900
          ) {
            return true;
          }
        }

        const util = getDateUtil();

        // Cập nhật lại tháng trên lịch
        this.monthDate = this.start ? new Date(this.start) : new Date();
        // get next month date
        this.nextMonthDate = util.nextMonth(this.monthDate);

        // Cập nhật giá trị UTC 0
        if (this.singleDatePicker) {
          this.$emit('update', this.dayjs(this.start).utc().format());
        }

        this.togglePicker(false, true);
      } else {
        this.start = null;
        this.$emit('update', this.start);
      }
    },
    // calculate initial month selected in picker
    // selectMonthDate() {
    //   const dt = this.end || new Date()
    //   if (this.singleDatePicker !== false) this.changeLeftMonth({ year: dt.getFullYear(), month: dt.getMonth() + 1 })
    //   else this.changeRightMonth({ year: dt.getFullYear(), month: dt.getMonth() + 1 })
    //   // // console.log('selectMonthDate', this.monthDate)
    // },
    dateFormatFn(classes, date) {
      const dt = new Date(date);
      dt.setHours(0, 0, 0, 0);
      const start = new Date(this.start);
      start.setHours(0, 0, 0, 0);
      const end = new Date(this.end);
      end.setHours(0, 0, 0, 0);

      classes['in-range'] = dt >= start && dt <= end;
      return classes;
      // return this.dateFormat ? this.dateFormat(classes, date) : classes
    },
    changeLeftMonth(value) {
      const newDate = new Date(value.year, value.month - 1, 1);
      this.monthDate = newDate;
      if (
        this.linkedCalendars ||
        this.$dateUtil.yearMonth(this.monthDate) >=
          this.$dateUtil.yearMonth(this.nextMonthDate)
      ) {
        this.nextMonthDate = this.$dateUtil.validateDateRange(
          this.$dateUtil.nextMonth(newDate),
          this.minDate,
          this.maxDate
        );
        // || this.singleDatePicker === 'range'
        if (
          !this.singleDatePicker &&
          this.$dateUtil.yearMonth(this.monthDate) ===
            this.$dateUtil.yearMonth(this.nextMonthDate)
        ) {
          this.monthDate = this.$dateUtil.validateDateRange(
            this.$dateUtil.prevMonth(this.monthDate),
            this.minDate,
            this.maxDate
          );
        }
      }
      /**
       * Emits event when the viewing month is changes. The second param is the index of the calendar.
       *
       * @param {monthDate} date displayed (first day of the month)
       * @param calendarIndex int 0 - first(left) calendar, 1 - second(right) calendar
       */
      this.$emit('change-month', this.monthDate, 0);
    },
    changeRightMonth(value) {
      const newDate = new Date(value.year, value.month - 1, 1);
      this.nextMonthDate = newDate;
      if (
        this.linkedCalendars ||
        this.$dateUtil.yearMonth(this.nextMonthDate) <=
          this.$dateUtil.yearMonth(this.monthDate)
      ) {
        this.monthDate = this.$dateUtil.validateDateRange(
          this.$dateUtil.prevMonth(newDate),
          this.minDate,
          this.maxDate
        );
        if (
          this.$dateUtil.yearMonth(this.monthDate) ===
          this.$dateUtil.yearMonth(this.nextMonthDate)
        ) {
          this.nextMonthDate = this.$dateUtil.validateDateRange(
            this.$dateUtil.nextMonth(this.nextMonthDate),
            this.minDate,
            this.maxDate
          );
        }
      }
      // check for same month fix
      if (
        this.$dateUtil.yearMonth(this.monthDate) ===
        this.$dateUtil.yearMonth(this.nextMonthDate)
      ) {
        this.nextMonthDate = this.$dateUtil.nextMonth(this.nextMonthDate);
      }

      this.$emit('change-month', this.nextMonthDate, 1);
    },

    dateClick(value) {
      if (this.readonly) return false;
      if (this.in_selection) {
        this.in_selection = false;

        /**
         * Emits event when the user clicks the second date and finishes selection
         *
         * @param {Date} date the date clicked
         */

        if (this.timePicker) {
          let tmp = value;
          tmp = this.dayjs(tmp)
            .tz(this.timeZone)
            .set('hour', this.dayjs(this.end).tz(this.timeZone).get('hour'));
          tmp = this.dayjs(tmp)
            .tz(this.timeZone)
            .set(
              'minute',
              this.dayjs(this.end).tz(this.timeZone).get('minute')
            );
          tmp = this.dayjs(tmp)
            .tz(this.timeZone)
            .set(
              'second',
              this.dayjs(this.end).tz(this.timeZone).get('second')
            );

          this.end = this.dayjs(tmp).tz(this.timeZone).format();
        } else {
          let tmp = value;
          tmp = this.dayjs(tmp).tz(this.timeZone).set('hour', 0);
          tmp = this.dayjs(tmp).tz(this.timeZone).set('minute', 0);
          tmp = this.dayjs(tmp).tz(this.timeZone).set('second', 0);

          this.end = this.dayjs(tmp).tz(this.timeZone).format();
        }

        this.$emit('finishSelection', value);
        this.onSelect();
        if (this.autoApply) this.clickedApply();
      } else {
        if (this.timePicker) {
          let tmp = value;
          let dateClone = new Date(value);
          if (this.start) {
            tmp = this.dayjs(tmp)
              .tz(this.timeZone)
              .set(
                'hour',
                this.dayjs(this.start).tz(this.timeZone).get('hour')
              );
            tmp = this.dayjs(tmp)
              .tz(this.timeZone)
              .set(
                'minute',
                this.dayjs(this.start).tz(this.timeZone).get('minute')
              );
            tmp = this.dayjs(tmp)
              .tz(this.timeZone)
              .set(
                'second',
                this.dayjs(this.start).tz(this.timeZone).get('second')
              );
          } else {
            tmp = this.dayjs(tmp).tz(this.timeZone).set('hour', 0);
            tmp = this.dayjs(tmp).tz(this.timeZone).set('minute', 0);
            tmp = this.dayjs(tmp).tz(this.timeZone).set('second', 0);
          }

          // Kiểm tra ngay được chọn trước 13/6/1975 thì cộng thêm giờ vì trước đó VN dùng GMT +8
          const specificDate = new Date('1975-06-13');
          if (dateClone.getTime() < specificDate.getTime()) {
            tmp = this.dayjs(tmp).add(3, 'h');
          }

          this.start = this.dayjs(tmp).tz(this.timeZone).format();
          this.end = this.dayjs(tmp).tz(this.timeZone).format();
        } else {
          let tmp = value;
          let dateClone = new Date(value);
          tmp = this.dayjs(tmp).tz(this.timeZone).set('hour', 0);
          tmp = this.dayjs(tmp).tz(this.timeZone).set('minute', 0);
          tmp = this.dayjs(tmp).tz(this.timeZone).set('second', 0);

          // Kiểm tra ngay được chọn trước 13/6/1975 thì cộng thêm giờ vì trước đó VN dùng GMT +8
          const specificDate = new Date('1975-06-13');
          if (dateClone.getTime() < specificDate.getTime()) {
            tmp = this.dayjs(tmp).add(3, 'h');
          }

          this.start = this.dayjs(tmp).tz(this.timeZone).format();
          this.end = this.dayjs(tmp).tz(this.timeZone).format();
        }

        if (!this.singleDatePicker || this.singleDatePicker === 'range') {
          this.in_selection = this.end;
          /**
           * Emits event when the user clicks the first date and starts selection
           *
           * @param {Date} date the date clicked
           */
          this.$emit('startSelection', this.start);
        } else {
          this.onSelect();
          if (this.autoApply) this.clickedApply();
        }
      }
    },
    hoverDate(value) {
      if (this.readonly) return false;

      let tmp_start = value;
      tmp_start = this.dayjs(tmp_start)
        .tz(this.timeZone)
        .set('hour', this.dayjs(this.start).tz(this.timeZone).get('hour'));
      tmp_start = this.dayjs(tmp_start)
        .tz(this.timeZone)
        .set('minute', this.dayjs(this.start).tz(this.timeZone).get('minute'));
      tmp_start = this.dayjs(tmp_start)
        .tz(this.timeZone)
        .set('second', this.dayjs(this.start).tz(this.timeZone).get('second'));

      let tmp_end = value;
      tmp_end = this.dayjs(tmp_end)
        .tz(this.timeZone)
        .set('hour', this.dayjs(this.end).tz(this.timeZone).get('hour'));
      tmp_end = this.dayjs(tmp_end)
        .tz(this.timeZone)
        .set('minute', this.dayjs(this.end).tz(this.timeZone).get('minute'));
      tmp_end = this.dayjs(tmp_end)
        .tz(this.timeZone)
        .set('second', this.dayjs(this.end).tz(this.timeZone).get('second'));

      const dt_end = this.dayjs(tmp_end).utc().tz(this.timeZone).format();
      const dt_start = this.dayjs(tmp_start).utc().tz(this.timeZone).format(); // this.normalizeDatetime(value, this.start)

      if (this.in_selection) {
        if (this.dayjs(this.in_selection).unix() <= this.dayjs(dt_end).unix())
          this.end = dt_end;
        if (this.dayjs(this.in_selection).unix() >= this.dayjs(dt_start).unix())
          this.start = dt_start;
      }

      /**
       * Emits event when the mouse hovers a date
       * @param {Date} value the date that is being hovered
       */
      this.$emit('hoverDate', value);
    },
    onClickPicker() {
      if (!this.disabled) {
        // this.togglePicker(null, true)
        this.open = true;
      }
    },
    togglePicker(value, event) {
      if (typeof value === 'boolean') {
        this.open = value;
      } else {
        this.open = !this.open;
      }

      if (event === true) {
        /**
         * Emits whenever the picker opens/closes
         * @param {boolean} open - the current state of the picker
         * @param {Function} togglePicker - function (show, event) which can be used to control the picker. where "show" is the new state and "event" is boolean indicating whether a new event should be raised
         */
        this.$emit('toggle', this.open, this.togglePicker);
      }
    },
    clickedApply() {
      // this.open = false

      this.togglePicker(false, true);

      /**
       * Emits when the user selects a range from the picker and clicks "apply" (if autoApply is true).
       * @param {json} value - json object containing the dates: {startDate, endDate}
       */

      // giờ trên lịch là giờ theo cấu hình timzone, convert về utc trước khi trả kết quả cuối

      if (this.singleDatePicker) {
        if (this.timePicker) {
          this.mask.start = this.dayjs(this.start)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY HH:mm');
          this.mask.end = this.dayjs(this.end)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY HH:mm');
        } else {
          this.mask.start = this.dayjs(this.start)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY');
          this.mask.end = this.dayjs(this.end)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY');
        }

        this.$emit('update', this.dayjs(this.start).utc().format());
      } else {
        this.$emit('update', {
          startDate: this.dayjs(this.start).utc().format(),
          endDate:
            this.singleDatePicker && this.singleDatePicker !== 'range'
              ? this.dayjs(this.start).utc().format()
              : this.dayjs(this.end).utc().format(),
        });
      }
    },
    clickCancel() {
      if (this.open) {
        // reset start and end
        // const { startDate } = this.dateRange
        // const { endDate } = this.dateRange
        // this.start = startDate ? new Date(startDate) : null
        // this.end = endDate ? new Date(endDate) : null
        // this.open = false
        this.in_selection = false;
        this.togglePicker(false, true);
      }
    },
    onSelect() {
      /**
       * Emits when the user selects a range from the picker.
       * @param {json} value - json object containing the dates: {startDate, endDate}
       */

      this.$emit('select', { startDate: this.start, endDate: this.end });
    },
    clickAway($event) {
      if (
        $event &&
        $event.target &&
        !this.$el.contains($event.target) &&
        this.$refs.dropdown &&
        !this.$refs.dropdown.contains($event.target)
      ) {
        this.clickCancel();
      }
    },
    clickRange(value) {
      console.log(value);
      this.in_selection = false;
      // // console.log(value)
      // if (this.$dateUtil.isValidDate(value[0]) && this.$dateUtil.isValidDate(value[1])) {
      // this.start = this.$dateUtil.validateDateRange(new Date(value[0]), this.minDate, this.maxDate)
      // this.end = this.$dateUtil.validateDateRange(new Date(value[1]), this.minDate, this.maxDate)

      this.start = this.dayjs(value[0]).tz(this.timeZone).format();
      this.end = this.dayjs(value[1]).tz(this.timeZone).format();

      this.changeLeftMonth({
        month: this.dayjs(this.start).get('month') + 1,
        year: this.dayjs(this.start).get('year'),
      });

      if (this.linkedCalendars === false) {
        this.changeRightMonth({
          month: this.dayjs(this.end).get('month') + 1,
          year: this.dayjs(this.end).get('year'),
        });
      }
      // } else {
      //   this.start = null
      //   this.end = null
      // }

      this.onSelect();

      if (this.autoApply) this.clickedApply();
    },
    onUpdateStartTime(value) {
      console.log(this.start);
      console.log(value);
      // Cập nhật lại giờ cho ngày
      this.start = this.dayjs(this.start)
        .tz(this.timeZone)
        .set('hour', value.hours);
      this.start = this.dayjs(this.start)
        .tz(this.timeZone)
        .set('minute', value.minutes);
      this.start = this.dayjs(this.start)
        .tz(this.timeZone)
        .set('second', value.seconds);
      this.start = this.dayjs(this.start).tz(this.timeZone).format();

      // this.start = this.$dateUtil.validateDateRange(start, this.minDate, this.maxDate)

      // if autoapply is ON we should update the value on time selection change
      if (this.autoApply) {
        if (this.singleDatePicker) {
          this.mask.start = this.dayjs(this.start)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY HH:mm');
          this.mask.end = this.dayjs(this.end)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY HH:mm');

          // this.start được trả về là theo timezone cấu hình, trả về utc 0
          this.$emit('update', this.dayjs(this.start).utc().format());
        } else {
          this.$emit('update', {
            startDate: this.dayjs(this.start).utc().format(),
            endDate:
              this.singleDatePicker && this.singleDatePicker !== 'range'
                ? this.dayjs(this.start).utc().format()
                : this.dayjs(this.end).utc().format(),
          });
        }

        // this.$emit('update', {
        //   startDate: this.start,
        //   endDate: this.singleDatePicker && this.singleDatePicker !== 'range' ? this.start : this.end,
        // })
      }
    },
    onUpdateEndTime(value) {
      this.end = this.dayjs(this.end)
        .tz(this.timeZone)
        .set('hour', value.hours);
      this.end = this.dayjs(this.end)
        .tz(this.timeZone)
        .set('minute', value.minutes);
      this.end = this.dayjs(this.end)
        .tz(this.timeZone)
        .set('second', value.seconds);
      this.end = this.dayjs(this.end).tz(this.timeZone).format();

      // this.end = this.$dateUtil.validateDateRange(end, this.minDate, this.maxDate)

      // if autoapply is ON we should update the value on time selection change
      if (this.autoApply) {
        if (this.singleDatePicker) {
          this.mask.start = this.dayjs(this.start)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY HH:mm');
          this.mask.end = this.dayjs(this.end)
            .utc()
            .tz(this.timeZone)
            .format('DD/MM/YYYY HH:mm');
          // this.start được trả về là theo timezone cấu hình, trả về utc 0
          this.$emit('update', this.dayjs(this.start).utc().format());
        } else {
          this.$emit('update', {
            startDate: this.dayjs(this.start).utc().format(),
            endDate:
              this.singleDatePicker && this.singleDatePicker !== 'range'
                ? this.dayjs(this.start).utc().format()
                : this.dayjs(this.end).utc().format(),
          });
        }
      }
    },
    handleEscape(e) {
      if (this.open && e.keyCode === 27 && this.closeOnEsc) {
        this.clickCancel();
      }
    },
  },
};
</script>

<style lang="scss">
@import '../assets/daterangepicker.scss';
</style>

<style lang="scss" scoped>
.calendars {
  display: flex;
  flex-wrap: wrap;
}

$week-width: 0px;

.reportrange-text {
  background: #fff;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
  overflow: hidden;
}

.daterangepicker {
  flex-direction: column;
  display: flex;
  width: auto;

  //les than 768
  @media screen and (max-width: 768px) {
    &.show-ranges {
      .drp-calendar.left {
        border-left: 0px;
      }

      .ranges {
        border-bottom: 1px solid #ddd;
        width: 100%;

        ::v-deep ul {
          display: flex;
          flex-wrap: wrap;
          width: auto;
        }
      }
    }
  }

  @media screen and (max-width: 541px) {
    .calendars-container {
      flex-wrap: wrap;
    }
  }

  /*from 540 to 768*/
  @media screen and (min-width: 540px) {
    min-width: 486px;
    &.show-weeknumbers {
      min-width: 486px + $week-width;
    }
  }

  //more than 768
  @media screen and (min-width: 768px) {
    &.show-ranges {
      min-width: 682px;

      &.show-weeknumbers {
        min-width: 682px + $week-width;
      }
    }
  }

  &.single {
    @media screen and (max-width: 340px) {
      min-width: 250px;

      &.show-weeknumbers {
        min-width: 250px + $week-width;
      }
    }

    @media screen and (min-width: 339px) {
      min-width: auto;
      &.show-ranges {
        min-width: 356px;

        &.show-weeknumbers {
          min-width: 356px + $week-width;
        }

        .drp-calendar.left {
          border-left: 1px solid #ddd;
        }

        .ranges {
          //width: auto;
          max-width: none;
          flex-basis: auto;
          border-bottom: 0;

          ::v-deep ul {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }

  &.show-calendar {
    display: block;
    top: auto;
  }
}

.daterangepicker {
  &.opensleft {
    /*top: 35px;*/
    right: 10px;
    left: auto;
  }

  &.openscenter {
    /*top: 35px;*/
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.opensright {
    /*top: 35px;*/
    left: 0;
    right: auto;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.vue-daterange-picker {
  position: relative;
  display: inline-block;
  min-width: 60px;

  .show-ranges.hide-calendars {
    width: 150px;
    min-width: 150px;
  }
}

.inline {
  .daterangepicker {
    position: static;

    &:before,
    &:after {
      display: none;
    }
  }
}
</style>
